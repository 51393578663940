import Axios from "axios";
import React from "react";
import _ from "lodash";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { CSVLink } from "react-csv";
import { defaultRanges, DateRange } from "react-date-range";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { removeUserSession, ROOT_URL } from "./Utils/Common";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const LocadMap = withGoogleMap((props) => {
	const position = {
		lat: Number(props.inv_details.latitude),
		lng: Number(props.inv_details.longitude),
	};

	return (
		<GoogleMap
			defaultZoom={18}
			defaultCenter={position}
			ref={(map) => {
				if (map) {
					const panorama = map.getStreetView();
					panorama.setPosition(position);
					panorama.setPov({
						heading: 265,
						pitch: 0,
					});
					panorama.setVisible(true);
				}
			}}
		>
			<Marker position={position} />
		</GoogleMap>
	);
});

function getInventoryDetails(inventory_id, cb) {
	Axios.post(
		`${ROOT_URL}/getInventoryDetails`,
		{ inventory_id },
		{
			headers: { authorization: localStorage.getItem("token") },
		}
	)
		.then((response) => {
			// console.log("Inventory", response.data);
			if (response.data.result) {
				cb(response.data.result);
			}
		})
		.catch((error) => console.log(error));
}

function getTrafficData(data, cb) {
	Axios.post(`${ROOT_URL}/getTrafficData`, data, {
		headers: { authorization: localStorage.getItem("token") },
	})
		.then((response) => {
			// console.log("getTrafficData", response.data, " type", data.type);
			cb(response.data);
		})
		.catch((error) => console.log(error));
}

const vehicleTypes = [
	{
		name: "car",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/car.png",
		count: 0,
	},
	{
		name: "bike",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/bike.png",
		count: 0,
	},
	{
		name: "cycle",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/cycle.png",
		count: 0,
	},
	{
		name: "truck",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/truck.png",
		count: 0,
	},
	{
		name: "bus",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/bus.png",
		count: 0,
	},
	{
		name: "person",
		url: "https://locauditooh-images.s3-ap-southeast-1.amazonaws.com/icons/man.png",
		count: 0,
	},
];

class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			unit: "day",
			active_year: { label: "2020", value: 2020 },
			approvedBookings: [],
			vendorBookingApprovalModal: false,
			currSelectedInvetory: "",
			dailyCountDateFilter: {
				startDate: moment().subtract(7, "days"),
				endDate: moment(),
			},
			daySpreadCountDateFilter: { startDate: moment(), endDate: moment() },
			exposureDateFilter: {
				startDate: moment().subtract(7, "days"),
				endDate: moment(),
			},
			activeDateFilter: "",
			showDateFilterModal: false,
			inventory_id: "60b22e5c0e4d13399c8b6161",
		};
	}

	componentDidMount() {
		const {
			dailyCountDateFilter,
			daySpreadCountDateFilter,
			exposureDateFilter,
			inventory_id,
		} = this.state;

		getInventoryDetails(inventory_id, (inv_details) => {
			this.setState({ inv_details });
		});

		getTrafficData(
			{
				inventoryId: inventory_id,
				startDate: dailyCountDateFilter["startDate"].format("MM/DD/YYYY"),
				endDate: dailyCountDateFilter["endDate"].format("MM/DD/YYYY"),
				type: "daily_count",
			},
			(trafficData) => {
				this.setState({
					...this.state,
					traffic_data: trafficData.traffic_data,
					total_traffic_count: trafficData.total_traffic_count,
					avg_traffic_count: trafficData.avg_traffic_count,
				});
			}
		);

		getTrafficData(
			{
				inventoryId: inventory_id,
				startDate: daySpreadCountDateFilter["startDate"].format("MM/DD/YYYY"),
				endDate: daySpreadCountDateFilter["endDate"].format("MM/DD/YYYY"),
				type: "day_spread",
			},
			(day_spread_traffic) => {
				this.setState({
					...this.state,
					day_spread_traffic: day_spread_traffic.traffic_data,
					day_spread_total: day_spread_traffic.total_traffic_count,
					day_spread_avg: day_spread_traffic.avg_traffic_count,
				});
			}
		);

		getTrafficData(
			{
				inventoryId: inventory_id,
				startDate: exposureDateFilter["startDate"].format("MM/DD/YYYY"),
				endDate: exposureDateFilter["endDate"].format("MM/DD/YYYY"),
				type: "exposure_count",
			},
			(exposure_traffic_data) => {
				this.setState({
					...this.state,
					exposure_traffic_data: exposure_traffic_data.traffic_data,
					exposure_traffic_total: exposure_traffic_data.total_traffic_count,
					exposure_traffic_avg: exposure_traffic_data.avg_traffic_count,
				});
			}
		);
	}

	openDateFilter(type) {
		this.setState({
			...this.state,
			showDateFilterModal: true,
			activeDateFilter: type,
		});
	}

	closeDateFilter() {
		this.setState({
			...this.state,
			showDateFilterModal: false,
			activeDateFilter: "",
		});
	}

	handleChange(type, payload) {
		this.setState({ ...this.state, [type]: payload });
	}

	getExposureConfigs = (vehicleTypes) => {
		const { exposure_traffic_data } = this.state;

		const totalExposure = [],
			categories = [],
			footfalls = [];

		const grouped = _.groupBy(exposure_traffic_data, (o) => {
			return o.start.split(" ")[0];
		});

		var types = {
			car: 3,
			bus: 12,
			bike: 2,
			cycle: 1,
			person: 1,
			truck: 2,
		};

		_.mapKeys(grouped, (value, key) => {
			categories.push({ label: key });

			let exposure = 0;

			_.mapKeys(types, (expVal, expKey) => {
				exposure += _.sum(value, expKey) * expVal;
			});

			const totalPerson = _.sum(value, "person") * 1;

			totalExposure.push({ value: exposure });
			footfalls.push({ value: totalPerson });
		});

		// console.log(totalExposure, footfalls, categories);

		if (totalExposure.length === 1) {
			categories.push(categories[0]);
			totalExposure.push(totalExposure[0]);
			footfalls.push(footfalls[0]);
		}

		console.log("total exposure ", totalExposure);

		console.log("category ", categories);

		console.log("footfalls ", footfalls);

		return {
			type: "mssplinearea",
			width: "100%",
			height: 400,
			dataFormat: "json",
			dataSource: {
				chart: {
					caption: "No. of Footfalls & Total Exposure Last Week",
					subCaption: "",
					xAxisName: "Day",
					yAxisName: "No. of Footfalls",
					theme: "fusion",
				},
				categories: [
					{
						category: categories,
					},
				],
				dataset: [
					{
						seriesname: "Total Exposure",
						data: totalExposure,
					},
					{
						seriesname: "Number of Footfall",
						data: footfalls,
					},
				],
			},
		};
	};

	getDaySpreadConfigs = () => {
		const categories = [];

		for (let i = 0; i < 24; i++) {
			categories.push({
				x: i,
				label: i + ":00",
				showverticalline: "0",
			});
		}

		const { day_spread_traffic } = this.state;

		const lastData = day_spread_traffic[day_spread_traffic.length - 1];
		const start = new Date(lastData.start.split(" ")[0]).getTime();
		const end = start + 24 * 60 * 60 * 1000;

		var filteredTrafficData = _.filter(
			day_spread_traffic,
			(t) =>
				t.type === "hrs" &&
				new Date(t.start).getTime() >= start &&
				new Date(t.end).getTime() <= end
		);

		// console.log(filteredTrafficData);

		var vehicleDataset = [];
		var peopleDataset = [];

		filteredTrafficData.forEach((f) => {
			const time = f.start.split(" ")[1];
			const hr = time.split(":")[0];

			// console.log(time, hr, f);

			vehicleDataset.push({
				x: hr,
				y: f.total_count - f.person ? f.person : 0,
			});

			peopleDataset.push({
				x: hr,
				y: f.person,
			});
		});

		// console.log(vehicleDataset);

		return {
			type: "scatter",
			width: "100%",
			height: 400,
			dataFormat: "json",
			dataSource: {
				chart: {
					caption: "Vehicle & People count Vs Time",
					subCaption: "",
					baseFont: "Helvetica Neue,Arial",
					xAxisName: "Time of the Day",
					yAxisName: "",
					xAxisMinValue: "9",
					xAxisMaxValue: "21",
					yNumberPrefix: "",
					xNumberSuffix: ":00 ",
					theme: "fusion",
				},
				categories: [
					{
						verticalLineDashed: "1",
						verticalLineDashLen: "1",
						verticalLineDashGap: "1",
						verticalLineThickness: "1",
						verticalLineColor: "#000000",
						category: categories,
					},
				],
				dataset: [
					{
						seriesname: "Vehicle Count",
						showregressionline: "1",
						data: vehicleDataset,
					},
					{
						seriesname: "People Count",
						showregressionline: "1",
						data: peopleDataset,
					},
				],
				vtrendlines: [
					{
						line: [
							{
								startvalue: "9",
								endvalue: "10",
								istrendzone: "1",
								displayvalue: " ",
								color: "#adebff",
								alpha: "25",
							},
							{
								startvalue: "10",
								endvalue: "11",
								istrendzone: "1",
								alpha: "0",
								displayvalue: "Very cold",
							},
							{
								startvalue: "11",
								endvalue: "12",
								istrendzone: "1",
								displayvalue: " ",
								color: "#adebff",
								alpha: "15",
							},
							{
								startvalue: "12",
								endvalue: "13",
								istrendzone: "1",
								alpha: "0",
								displayvalue: "Cold",
							},
							{
								startvalue: "13",
								endvalue: "14",
								istrendzone: "1",
								alpha: "0",
								displayvalue: "Moderate",
							},
							{
								startvalue: "14",
								endvalue: "15",
								istrendzone: "1",
								alpha: "0",
								displayvalue: "Hot",
							},
							{
								startvalue: "15",
								endvalue: "16",
								istrendzone: "1",
								displayvalue: " ",
								color: "#f2a485",
								alpha: "15",
							},
							{
								startvalue: "16",
								endvalue: "17",
								istrendzone: "1",
								alpha: "0",
								displayvalue: "Very hot",
							},
							{
								startvalue: "17",
								endvalue: "18",
								istrendzone: "1",
								displayvalue: " ",
								color: "#f2a485",
								alpha: "25",
							},
						],
					},
				],
			},
		};
	};

	getPieChartConfigs = (vehicleTypes) => {
		// console.log("Vehcile Types ", vehicleTypes);
		var data = [];
		vehicleTypes.forEach((v) => {
			if (v.name !== "person") {
				data.push({
					label: v.name,
					value: v.count,
				});
			}
		});

		return {
			type: "pie3d",
			width: "100%",
			height: 400,
			dataFormat: "json",
			dataSource: {
				chart: {
					caption: "Split of Vehicles",
					subCaption: "",
					enableSmartLabels: "0",
					startingAngle: "0",
					showPercentValues: "1",
					decimals: "1",
					useDataPlotColorForLabels: "1",
					theme: "fusion",
				},
				data: data,
			},
		};
	};

	filterTrafficData(type) {
		const {
			dailyCountDateFilter,
			daySpreadCountDateFilter,
			exposureDateFilter,
			inventory_id,
		} = this.state;

		switch (type) {
			case "daySpreadCountDateFilter":
				getTrafficData(
					{
						inventoryId: inventory_id,
						startDate:
							daySpreadCountDateFilter["startDate"].format("MM/DD/YYYY"),
						endDate: daySpreadCountDateFilter["endDate"].format("MM/DD/YYYY"),
						type: "day_spread",
					},
					(day_spread_traffic) => {
						this.setState({
							...this.state,
							day_spread_traffic: day_spread_traffic.traffic_data,
							day_spread_total: day_spread_traffic.total_traffic_count,
							day_spread_avg: day_spread_traffic.avg_traffic_count,
						});
					}
				);
				break;
			case "dailyCountDateFilter":
				getTrafficData(
					{
						inventoryId: inventory_id,
						startDate: dailyCountDateFilter["startDate"].format("MM/DD/YYYY"),
						endDate: dailyCountDateFilter["endDate"].format("MM/DD/YYYY"),
						type: "daily_count",
					},

					(trafficData) => {
						this.setState({
							...this.state,
							traffic_data: trafficData.traffic_data,
							total_traffic_count: trafficData.total_traffic_count,
							avg_traffic_count: trafficData.avg_traffic_count,
						});
					}
				);
				break;
			case "exposureDateFilter":
				getTrafficData(
					{
						inventoryId: inventory_id,
						startDate: exposureDateFilter["startDate"].format("MM/DD/YYYY"),
						endDate: exposureDateFilter["endDate"].format("MM/DD/YYYY"),
						type: "exposure_count",
					},
					(exposure_traffic_data) => {
						this.setState({
							...this.state,
							exposure_traffic_data: exposure_traffic_data.traffic_data,
							exposure_traffic_total: exposure_traffic_data.total_traffic_count,
							exposure_traffic_avg: exposure_traffic_data.avg_traffic_count,
						});
					}
				);
				break;
			default: {
			}
		}

		this.closeDateFilter();
	}

	siteImg(photo) {
		if (photo) {
			return photo;
		} else {
			return "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/No_image_3x4.svg/1024px-No_image_3x4.svg.png";
		}
	}

	handleLogout = () => {
		removeUserSession();
		this.props.history.push("/login");
	};

	render() {
		const { inv_details, avg_traffic_count, traffic_data } = this.state;

		// console.log(avg_traffic_count);

		if (avg_traffic_count) {
			_.mapKeys(avg_traffic_count, (value, key) => {
				var foundIndex = _.findIndex(vehicleTypes, (o) => {
					return o.name === key;
				});
				if (foundIndex !== -1) {
					vehicleTypes[foundIndex]["count"] = value; // FOr the Day
				}
			});
		}

		return (
			<div className="page-container page-navigation-toggled page-container-wide">
				<div className="page-content">
					<button onClick={this.handleLogout}>Logout</button>
					<div
						className="text-center heading-text"
					>
						Locad Camera Module
					</div>
					<div className="container-fluid" style={{ padding: "1%" }}>
						{/* ==== First Panel ===== */}
						{inv_details && (
							<div
								style={{
									paddingTop: "15px",
									paddingBottom: "15px",
									paddingLeft: "15px",
									marginTop: "20px",
								}}
								className="panel panel-default"
							>
								<div className="col-md-3">
									<img
										alt="img"
										className="img-responsive img-rounded tile-shadow"
										style={{
											height: "35vh",
											width: "100%",
										}}
										src={this.siteImg(inv_details.site_map)}
									/>
								</div>

								<div className="col-md-3">
									<div
										className="tile-shadow"
										style={{ background: "rgb(236, 64, 122)" }}
									>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.site_code}</p>
											<p>Site Code</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.location}</p>
											<p>Location</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">
												{inv_details.city} <span className="fa fa-pie"></span>
											</p>
											<p>City</p>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div
										className="tile-shadow"
										style={{ background: "rgb(156, 39, 176)" }}
									>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.media_type}</p>
											<p>Media Type</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.media_owner}</p>
											<p>Media Owner</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.availability}</p>
											<p>Availability</p>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div
										className="tile-shadow"
										style={{ background: "rgb(3, 169, 244)" }}
									>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">{inv_details.lighting}</p>
											<p>Lighting</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">A,B</p>
											<p>SEC Classification</p>
										</div>
										<div style={{ overflow: "hidden" }}>
											<p className="tile_title">
												{inv_details && inv_details.campaigns_done.length}
											</p>
											<p>Total Campaigns</p>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* ===== TRAFFIC PANEL ========= */}
						{inv_details && inv_details.traffic_count && (
							<div>
								<div
									style={{ padding: "2%", marginTop: "20px" }}
									className="panel panel-default"
								>
									<h2 className="jss625"> Traffic Analytics</h2>
									<div
										className="col-md-7"
										style={{ marginTop: "20px" }}
										id="vid"
									>
										{inv_details.live_feed && (
											<video
												width="100%"
												controls
												autoPlay
												muted
											>
												<source src={inv_details.live_feed} type="video/mp4" />
												Your browser does not support HTML video.
											</video>
										)}
									</div>
									<div className="col-md-1"></div>
									<div
										className="col-md-4"
										style={{
											marginTop: "20px",
											height: "500px",
											overflowY: "scroll",
										}}
									>
										{inv_details.cam_screenshots &&
											inv_details.cam_screenshots.map((s) => (
												<div
													key={_.uniqueId()}
													style={{ borderRadius: "4px", paddingBottom: "20px" }}
												>
													<img
														className="img-responsive"
														src={s.url}
														alt="url"
													/>
												</div>
											))}
									</div>
								</div>

								<div className="row">
									<div className="col-xs-7">
										<div
											className="panel panel-default"
											style={{ padding: "2%" }}
										>
											<div className="row">
												<div className="col-md-7">
													<h4>Avg Daily Count</h4>
												</div>
												<div className="col-md-5">
													{traffic_data && (
														<div
															className="pull-right"
															style={{ marginLeft: "5px" }}
														>
															<CSVLink
																filename={"Traffic Analysis.csv"}
																data={traffic_data}
																className="btn btn-success "
															>
																<span className="fa fa-download"></span>
															</CSVLink>
														</div>
													)}
													<div className="pull-right btn-group">
														{!_.isEmpty(this.state.dailyCountDateFilter) && (
															<button className="btn btn-default">
																{this.state.dailyCountDateFilter[
																	"startDate"
																].format("MM/DD/YYYY") +
																	" - " +
																	this.state.dailyCountDateFilter[
																		"endDate"
																	].format("MM/DD/YYYY")}
															</button>
														)}
														<button
															className="btn btn-primary"
															onClick={this.openDateFilter.bind(
																this,
																"dailyCountDateFilter"
															)}
														>
															<span className="fa fa-calendar dashboard-step6"></span>
														</button>
													</div>
												</div>
											</div>

											{vehicleTypes.map(
												(v) =>
													v.count && (
														<div
															key={_.uniqueId()}
															style={{
																margin: "1%",
																width: "23%",
																display: "inline-block",
															}}
														>
															<div
																style={{
																	padding: "10px",
																	// border:"4px solid blue",
																	borderRadius: "20px",
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<div style={{ padding: "10px" }}>
																	<img
																		src={v.url}
																		style={{ width: "100%" }}
																		alt="url"
																	/>
																</div>

																<button className="btn">
																	<span
																		style={{
																			fontWeight: "bold",
																			fontSize: "1.2em",
																		}}
																	>
																		{v.count}
																	</span>
																</button>
															</div>
														</div>
													)
											)}
										</div>
									</div>

									<div className="col-xs-5">
										<div
											className="panel panel-default"
											style={{ padding: "2%" }}
										>
											<ReactFC {...this.getPieChartConfigs(vehicleTypes)} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-8">
										{this.state.day_spread_traffic && (
											<div className="row panel">
												<div
													className="pull-right btn-group"
													style={{ margin: "20px" }}
												>
													{!_.isEmpty(this.state.daySpreadCountDateFilter) && (
														<button className="btn btn-default">
															{this.state.daySpreadCountDateFilter[
																"startDate"
															].format("MM/DD/YYYY") +
																" - " +
																this.state.daySpreadCountDateFilter[
																	"endDate"
																].format("MM/DD/YYYY")}
														</button>
													)}
													<button
														className="btn btn-primary"
														onClick={this.openDateFilter.bind(
															this,
															"daySpreadCountDateFilter"
														)}
													>
														<span className="fa fa-calendar dashboard-step6"></span>
													</button>
												</div>
												{!_.isEmpty(this.state.day_spread_traffic) && (
													<ReactFC {...this.getDaySpreadConfigs()} />
												)}
												{_.isEmpty(this.state.day_spread_traffic) && (
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															minHeight: "470px",
															fontWeight: "bold",
														}}
													>
														No Data
													</div>
												)}
											</div>
										)}
									</div>
									<div className="col-xs-4">
										{this.state.exposure_traffic_data && (
											<div className="row panel">
												<div
													className="pull-right btn-group"
													style={{ margin: "20px" }}
												>
													{!_.isEmpty(this.state.exposureDateFilter) && (
														<button className="btn btn-default">
															{this.state.exposureDateFilter[
																"startDate"
															].format("MM/DD/YYYY") +
																" - " +
																this.state.exposureDateFilter["endDate"].format(
																	"MM/DD/YYYY"
																)}
														</button>
													)}
													<button
														className="btn btn-primary"
														onClick={this.openDateFilter.bind(
															this,
															"exposureDateFilter"
														)}
													>
														<span className="fa fa-calendar dashboard-step6"></span>
													</button>
												</div>
												<ReactFC
													key={_.uniqueId()}
													{...this.getExposureConfigs(vehicleTypes)}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						)}

						{/* ===== MAP Panel ========= */}
						<div
							style={{ padding: "2%", marginTop: "20px" }}
							className="panel panel-default"
						>
							<h2 className="jss625"> Site Location</h2>
							<div className="col-xs-8" style={{ marginTop: "20px" }}>
								{inv_details && (
									<div style={{ height: "400px", width: "100%" }}>
										<LocadMap
											containerElement={<div />}
											mapElement={<div id="inv_map_analy" />}
											inv_details={inv_details}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				{/* //---- Date Filter Modal ----------// */}
				<Modal
					show={this.state.showDateFilterModal}
					onHide={this.closeDateFilter.bind(this)}
				>
					<Modal.Header className="bg-success">
						<Modal.Title>
							<h3 className="text-white m-0">Choose a Date Range</h3>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DateRange
							linkedCalendars={true}
							ranges={defaultRanges}
							onInit={this.handleChange.bind(this, this.state.activeDateFilter)}
							onChange={this.handleChange.bind(
								this,
								this.state.activeDateFilter
							)}
							theme={{
								Calendar: { width: 200 },
								PredefinedRanges: { marginLeft: 10, marginTop: 10 },
							}}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button
							className="btn btn-success"
							onClick={this.filterTrafficData.bind(
								this,
								this.state.activeDateFilter
							)}
						>
							Save
						</Button>
						<Button
							className="btn btn-danger"
							onClick={this.closeDateFilter.bind(this)}
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default Dashboard;
