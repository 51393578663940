import React, { useState } from "react";
import axios from "axios";
import { ROOT_URL, setUserSession } from "./Utils/Common";

function Login(props) {
	const [loading, setLoading] = useState(false);

	const [error, setError] = useState(null);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	// handle button click of login form
	const handleSubmit = (e) => {
		e.preventDefault();

		setEmail("");
		setLoading(true);
		axios
			.post(ROOT_URL + "/signin", {
				email,
				password,
			})
			.then((response) => {
				console.log(response.data);
				setLoading(false);
				setUserSession(response);
				props.history.push("/dashboard");
			})
			.catch((error) => {
				setLoading(false);
				if (error.response.status === 401) setError("Check Credentials");
				else setError("Something went wrong. Please try again later.");
			});
	};

	// console.log(error);

	return (
		<div className="container">
			<div className="row  card-1N">
				<div className="col-md-8 login-image">
					<div
						style={{
							position: "absolute",
							top: "10%",
							left: "30%",
							fontWeight: "1000",
							color: "#087FF5",
							fontSize: "30px",
						}}
					>
						Locad Camera Module
					</div>
				</div>
				<div className="col-md-4" style={{ padding: "30px" }}>
					<div className="col-md-12">
						<img
							alt="locad logo"
							src="./images/locad.png"
							width="150"
							style={{ float: "right", marginBottom: "50px" }}
						/>
					</div>

					<div
						className="text-center"
						style={{
							fontWeight: "1000",
							color: "#087FF5",
							fontSize: "30px",
							marginBottom: "30px",
						}}
					>
						Login Account
					</div>
					<div className="row" style={{ marginTop: "10px" }}>
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label>Email address</label>
								<input
									className="form-control"
									placeholder="Enter email"
									onChange={handleEmailChange}
								/>
							</div>

							<div className="form-group">
								<label>Password</label>
								<input
									type="password"
									className="form-control"
									placeholder="Enter password"
									onChange={handlePasswordChange}
								/>
							</div>

							<div className="form-group">
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="customCheck1"
									/>
									<label
										className="custom-control-label"
										htmlFor="customCheck1"
									>
										Remember me
									</label>
								</div>
							</div>

							<button
								disabled={loading}
								type="submit"
								className="btn btn-primary btn-block"
							>
								Submit
							</button>
						</form>
						<div className="col-md-12">
							<img
								alt="locaudit trust seal"
								src="./images/trust_seal.png"
								width="100"
								style={{ float: "right", marginTop: "10px" }}
							/>
						</div>
					</div>
					{error && <div className="text-danger mt-3">{error}</div>}
				</div>
			</div>
			<div
				className="row text-center"
				style={{ marginTop: "10px", fontSize: "16px", fontWeight: "700" }}
			>
				Copyright © 2021 All Rights Reserved.
			</div>
			<div
				className="row text-center"
				style={{ marginTop: "10px", fontSize: "16px", fontWeight: "700" }}
			>
				Sector 6 Technologies LLP | LOCAD PTE LD.
			</div>
		</div>
	);
}

export default Login;
