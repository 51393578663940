import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import Login from "./Login";
import Dashboard from "./Dashboard";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import { getToken } from "./Utils/Common";

function App() {
	useEffect(() => {
		const token = getToken();
		if (!token) {
			return;
		}
	}, []);

	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<PublicRoute path="/login" component={Login} />
					<PrivateRoute path="/" component={Dashboard} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
