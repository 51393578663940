// return the user data from the session storage
export const getUser = () => {
	const userStr = localStorage.getItem("user");
	if (userStr) return JSON.parse(userStr);
	else return null;
};

// return the token from the session storage
export const getToken = () => {
	return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("user");
	localStorage.clear();
};

// set the token and user from the session storage
export const setUserSession = (response) => {
	localStorage.setItem("token", response.data.token);
	localStorage.setItem("user", JSON.stringify(response.data.user));
	localStorage.setItem("id", response.data.id);
	localStorage.setItem("policies", response.data.policies);
	localStorage.setItem("role_policies", response.data.role_policies);
	localStorage.setItem("role_name", response.data.role_name);
};

const PROD_ROOT_URL = "https://locauditoohnewapi.locad.net";

export const ROOT_URL = PROD_ROOT_URL;
